<template>
  <div>
    <el-upload
      action='' 
      :auto-upload='false' 
      :on-change='handleFileUpload'
      :on-remove='handleFileRemove' 
      :multiple='false'
      :file-list='fileList'
      accept='.doc,.docx'>
      <div
        class='w-64 lg:w-96 py-2 border rounded-md text-white hover:shadow-md flex flex-row justify-center items-center gap-x-2'
        :style='eventMainThemeColorBg'>
        <i class='el-icon-upload text-xl'></i>
        <div class='text-sm uppercase'>upload</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditFormFieldFileUpload',
  props: [
    'metaData',
    'value'
  ],
  data () {
    return {
      inputFile: null,
    }
  },
  watch: {
    inputFile: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:fileValue', newVal)
        }
      },
      deep: true,
      immediate: true
    },
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.inputFile = newVal
        }
      }, 
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
    type () {
      return typeof this.inputFile
    },
    fileList () {
      if (this.inputFile && typeof this.inputFile === 'string') {
        return [{name: this.inputFile, url: this.inputFile}]
      } else if (this.inputFile && typeof this.inputFile === 'object') {
        return [this.inputFile]
      } else {
        return []
      }
    },
    eventMainThemeColorBg () {
      return `color: ${this.eventMainThemeColor};`
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    handleFileUpload (file) {
      this.inputFile = { file: file.raw, name: file.name }
    },
    handleFileRemove () {
      this.inputFile = null
    },
  }
}
</script>ㄷ